import { Link } from "react-router-dom"

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップページ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "スケジュール", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: "/system", blank: false, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        {nameJp: "女子求人",       nameEn: "RECRUIT", path: "https://www.girlsheaven-job.net/hyogo/ma-47/sa-463/heaven_himegi/?of=y", blank: true}, 
                        {nameJp: "男子求人",       nameEn: "RECRUIT", path: "https://mensheaven.jp/8/heaven_himegi/?of=y", blank: true},
                        // {nameJp: "写メ日記",     nameEn: "DIARY", path: "/", blank: true, img: ""},
                        // {nameJp: "ムービー",     nameEn: "MOVIE", path: "/", blank: true, img: ""},
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        if(el.blank===false){
                            return(<li key={el.nameEn}><Link to={el.path}>{el.nameJp}<span>{el.nameEn}</span></Link></li>); }
                        else{ return(<li key={el.nameEn}><a href={el.path}
                                            target="_blank" rel="noreferrer noopner">{el.nameJp}<span>{el.nameEn}</span></a></li>); }
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;