import {h2Unlinked} from"./core/core";
import {getPriceChart } from "./core/defines"
import SideNav from "./components/sideNav";


function System(){
    
    return(
        <div className="container bg-w-alpha">
            <section className="main-colmn">
                {h2Unlinked("SYSTEM")}
                    <img src={getPriceChart("2024-04-26T00:00:00")} alt="料金表" />
            </section>
            <SideNav />
        </div>
    )
}


export default System